import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import SidebarLayout from 'src/layouts/SidebarLayout'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Home = Loader(lazy(() => import('src/pages/Home')))
const FirstToGuess = Loader(lazy(() => import('src/pages/FirstToGuess')))
const SubsVsViewers = Loader(lazy(() => import('src/pages/SubsVsViewers')))
const WouldYouRather = Loader(lazy(() => import('src/pages/WouldYouRather')))
const Jungle = Loader(lazy(() => import('src/pages/Jungle')))

const routes = [
  {
    path: '*',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'first',
        element: <FirstToGuess />
      },
      {
        path: 'subsvsviewers',
        element: <SubsVsViewers />
      },
      {
        path: 'wouldyourather',
        element: <WouldYouRather />
      },
      {
        path: 'jungle',
        element: <Jungle />
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ]
  }
]

export default routes
