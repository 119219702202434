import { useContext } from 'react'
import { Box, ToggleButtonGroup, ToggleButton, Button } from '@mui/material'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone'
import MainProvider from 'src/contexts/MainContext'

const HeaderButtons = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const ctx = useContext(MainProvider.context)

  return (
    <Box className={classes.root}>
      <div>
        <Button
          onClick={() => ctx.setModalSupport(true)}
          variant="contained"
          color="info"
          startIcon={<VolunteerActivismIcon />}
          className={classes.button}
        >
          {t('common.supportButton')}
        </Button>
        <Button
          onClick={() => ctx.setModalContact(true)}
          variant="outlined"
          color="warning"
          startIcon={<EmailTwoToneIcon />}
          className={classes.button}
        >
          {t('common.contactButton')}
        </Button>
      </div>
      <ToggleButtonGroup size="small" exclusive value={i18n.language.slice(0, 2)} color="info" className={classes.languageButtons}>
        <ToggleButton className={classes.languageButton} value="en" key="enDesk" onClick={() => i18n.changeLanguage('en-US')}>
          English
        </ToggleButton>
        <ToggleButton className={classes.languageButton} value="es" key="esDesk" onClick={() => i18n.changeLanguage('es-ES')}>
          Spanish
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      flexDirection: 'row'
    }
  },
  button: {
    marginRight: '10px !important',
    padding: '3px 8px !important',
    fontSize: '12px !important',
    '@media (min-width: 601px)': {
      fontSize: '14px !important',
      padding: '6px 16px !important',
      marginRight: '50px !important'
    }
  },
  languageButtons: {
    marginRight: '10px !important',
    marginTop: '10px !important',
    justifyContent: 'end',
    '@media (min-width: 601px)': {
      marginRight: '30px !important',
      marginTop: '0px !important'
    }
  },
  languageButton: {
    '@media (min-width: 601px)': {
      padding: '0px 12px !important'
    }
  }
}))

export default HeaderButtons
