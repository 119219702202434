import { useContext } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import MainProvider from '../contexts/MainContext'

const UserTag = () => {
  const ctx = useContext(MainProvider.context)
  const { width, height } = useWindowSize()
  return (
    <>
      {ctx.confetti && (
        <Confetti width={width} height={height} recycle={false} numberOfPieces={1250} gravity={0.09} tweenDuration={10000} />
      )}
    </>
  )
}

export default UserTag
