import { useContext } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MainProvider from 'src/contexts/MainContext'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'

const ModalSupport = () => {
  const { t } = useTranslation()
  const ctx = useContext(MainProvider.context)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      severity="info"
      open={ctx.alertSupport}
      autoHideDuration={6000}
      resumeHideDuration={0}
      disableWindowBlurListener={true}
      onClose={() => ctx.setAlertSupport(false)}
    >
      <Alert icon={<VolunteerActivismIcon fontSize="inherit" />} variant="filled" severity="success" color="info">
        {t('support.alert')}
      </Alert>
    </Snackbar>
  )
}

export default ModalSupport
