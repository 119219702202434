import tinycolor from 'tinycolor2'

export const checkMessageLength = (message, length) => {
  if (message.length > length) {
    return message.substring(0, length) + '...'
  } else {
    return message
  }
}

export const checkColorReadability = (color) => {
  if (color) {
    let tinyColor = tinycolor(color)
    if (tinyColor.isDark()) {
      return tinyColor.lighten(20).toString()
    } else {
      return color
    }
  } else {
    return '#dedede'
  }
}

export const resetAnimation = (animation) => {
  if (animation === '1') {
    return '2'
  } else if (animation === '2') {
    return '3'
  } else {
    return '1'
  }
}

export const sendEvent = (path, title) => {
  if (window.goatcounter) {
    window.goatcounter.count({
      path,
      title,
      event: true
    })
  }
}
