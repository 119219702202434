import { createContext, useState, useEffect } from 'react'
import { sendEvent } from 'src/services/_utils'

const context = createContext(null)

const MainProvider = ({ children }) => {
  const [confetti, setConfetti] = useState(false)
  const [streamChannel, setStreamChannel] = useState(localStorage.getItem('streamChannel') ?? '')
  const [mute, setMute] = useState(JSON.parse(localStorage.getItem('mute')) ?? false)
  const [onlyFirstMessage, setOnlyFirstMessage] = useState(JSON.parse(localStorage.getItem('onlyFirstMessage')) ?? false)
  const [seconds, setSeconds] = useState(JSON.parse(localStorage.getItem('seconds')) ?? 60)
  const [modalContact, setModalContact] = useState(false)
  const [modalSupport, setModalSupport] = useState(false)
  const [modalExamples, setModalExamples] = useState(false)
  const [alertSupport, setAlertSupport] = useState(false)

  useEffect(() => {
    localStorage.setItem('mute', JSON.stringify(mute))
    localStorage.setItem('streamChannel', streamChannel)
    localStorage.setItem('onlyFirstMessage', JSON.stringify(onlyFirstMessage))
    localStorage.setItem('seconds', JSON.stringify(seconds))
  }, [mute, streamChannel, onlyFirstMessage, seconds])

  useEffect(() => {
    if (modalContact) {
      sendEvent('modal-contact', 'modal-contact')
    }
  }, [modalContact])
  useEffect(() => {
    if (modalSupport) {
      sendEvent('modal-support', 'modal-support')
    }
  }, [modalSupport])
  useEffect(() => {
    if (modalExamples) {
      sendEvent('modal-examples', 'modal-examples')
    }
  }, [modalExamples])

  const ctx = {
    streamChannel,
    setStreamChannel,
    confetti,
    setConfetti,
    mute,
    setMute,
    onlyFirstMessage,
    setOnlyFirstMessage,
    seconds,
    setSeconds,
    modalContact,
    setModalContact,
    modalSupport,
    setModalSupport,
    modalExamples,
    setModalExamples,
    alertSupport,
    setAlertSupport
  }

  return <context.Provider value={ctx}>{children}</context.Provider>
}

MainProvider.context = context

export default MainProvider
