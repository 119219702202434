import { Box, Hidden } from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 1)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        margin-right: 10px;
        @media (max-width: 601px): {
          margin-right: 0px;
        }
`
)

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(2)};
        display: flex;
        align-items: center;
`
)

const LogoText = styled(Box)(
  //font-size: ${theme.typography.pxToRem(15)};
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(20)};
        font-weight: ${theme.typography.fontWeightBold};
        margin-left: 10px;
`
)

function Logo() {
  return (
    <LogoWrapper to="/">
      <img alt="logo" src="/static/images/blobDance2x.gif" style={{ width: 40, height: 40 }} />
      <Hidden smDown>
        <LogoTextWrapper>
          <LogoText>
            blobDance<span style={{ color: '#7362e3', fontSize: 16 }}>.com</span>
          </LogoText>
        </LogoTextWrapper>
      </Hidden>
    </LogoWrapper>
  )
}

export default Logo
