import { useRoutes } from 'react-router-dom'
import routes from './router'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Confetti from 'src/components/Confetti'
import ThemeProvider from './theme/ThemeProvider'
import ModalContact from 'src/components/ModalContact'
import ModalSupport from 'src/components/ModalSupport'
import ModalExamples from 'src/components/ModalExamples'
import SupportAlert from 'src/components/SupportAlert'
import LanguageSelector from 'src/components/LanguageSelector'
import { CssBaseline } from '@mui/material'
import { useTranslation } from 'react-i18next'

const App = () => {
  const content = useRoutes(routes)
  const { i18n } = useTranslation()

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Confetti />
        <ModalContact />
        <ModalSupport />
        <ModalExamples />
        <SupportAlert />
        {i18n.language === 'en' && <LanguageSelector />}
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
