import { useContext } from 'react'
import { Dialog, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import MainProvider from 'src/contexts/MainContext'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { sendEvent } from 'src/services/_utils'

const ModalSupport = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ctx = useContext(MainProvider.context)

  return (
    <Dialog onClose={() => ctx.setModalSupport(false)} open={ctx.modalSupport} className={classes.root}>
      <div className={classes.title}>
        {t('support.hi')} <img alt="logo" src="/static/images/blobDance.png" className={classes.logo} /> {t('support.title')}
      </div>
      <div className={classes.subtitle}>{t('support.subtitle')}</div>
      <div className={classes.paypal}>
        <Chip label={`PayPal / ${t('support.card')}`} style={{ backgroundColor: '#009CDE' }} />
        <DoubleArrowIcon className={classes.icon} color="primary" />
        <a
          href="https://www.paypal.com/donate?hosted_button_id=NHBF7N9FHUFBE"
          target="_blank"
          onClick={() => sendEvent('paypal', 'paypal')}
        >
          <img alt="logo" src="/static/images/donate.png" style={{ verticalAlign: 'middle' }} />
        </a>
      </div>
      <div className={classes.divider} />
      <div className={classes.btc}>
        <Chip label="BTC" variant="outlined" style={{ borderColor: '#f2a900', color: '#f2a900' }} />
        <DoubleArrowIcon className={classes.icon} color="primary" />
        <span className={classes.btcString} onClick={() => sendEvent('btc', 'btc')}>
          bc1qn30xym7kyduhdhe6rjq0j8tm3fv452rj79mv0htqlvwh233wn9vqfjmkz3
        </span>
      </div>
      <div className={classes.eth}>
        <Chip label="ETH" variant="outlined" style={{ borderColor: '#ecf0f1', color: '#ecf0f1' }} />
        <DoubleArrowIcon className={classes.icon} color="primary" />
        <span className={classes.ethString} onClick={() => sendEvent('eth', 'eth')}>
          0xe80349A472Ef1f1D5fda72d256d7bF8d416d67Ea
        </span>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      padding: '35px 15px',
      textAlign: 'center',
      minWidth: 320,
      alignItems: 'center',
      '@media (min-width: 601px)': {
        minWidth: 670,
        padding: '35px 25px'
      }
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#a770ff',
    '@media (min-width: 601px)': {
      fontSize: 28,
      marginBottom: 25
    }
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    marginBottom: 25,
    color: '#e3e3e3',
    '@media (min-width: 601px)': {
      fontSize: 20,
      marginBottom: 40
    }
  },
  divider: {
    width: '90%',
    height: 1,
    backgroundColor: '#525252',
    marginBottom: 25,
    marginTop: 25,
    '@media (min-width: 601px)': {
      marginBottom: 35,
      marginTop: 35
    }
  },
  icon: {
    fontSize: '22px !important',
    marginLeft: 8,
    marginRight: 8,
    verticalAlign: 'middle'
  },
  paypal: {
    marginBottom: 0
  },
  btc: {
    marginBottom: 10
  },
  btcString: {
    fontSize: 9,
    '@media (min-width: 601px)': {
      fontSize: 14
    }
  },
  ethString: {
    fontSize: 11,
    '@media (min-width: 601px)': {
      fontSize: 14
    }
  },
  logo: {
    verticalAlign: 'middle',
    width: 25,
    '@media (min-width: 601px)': {
      width: 35
    }
  }
}))

export default ModalSupport
