import { useContext } from 'react'
import { Dialog, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import MainProvider from 'src/contexts/MainContext'

const ModalExamples = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ctx = useContext(MainProvider.context)

  return (
    <Dialog onClose={() => ctx.setModalExamples(false)} open={ctx.modalExamples} className={classes.root}>
      <div className={classes.title}>
        <span style={{ color: 'rgba(237, 237, 237, 0.7)' }}>{t('examples.mode')}</span>
        <Avatar
          sx={{ marginRight: '0px', marginLeft: '10px', width: 45, height: 45, display: 'inline-block', verticalAlign: 'middle' }}
          variant="rounded"
          alt="first"
          src="/static/images/first.png"
        />{' '}
        {t('firstToGuess.title')}
      </div>
      <List className={classes.rootList}>
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="akinator" src="/static/images/akinator.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary="Chat VS Akinator" secondary={t('examples.akinatorDesc')} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="draw" src="/static/images/draw.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary={t('examples.drawTitle')} secondary={t('examples.drawDesc')} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="draw" src="/static/images/galaxy-brain.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary={t('examples.galaxyBrainTitle')} secondary={t('examples.galaxyBrainDesc')} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="music" src="/static/images/music.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary={t('examples.musicTitle')} secondary={t('examples.musicDesc')} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="draw" src="/static/images/imitation.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary={t('examples.imitationTitle')} secondary={t('examples.imitationDesc')} />
        </ListItem>
      </List>
      <div style={{ width: '90%', minHeight: 1, backgroundColor: '#8C7CF0', marginBottom: 24 }} />
      <div className={classes.title}>
        <span style={{ color: 'rgba(237, 237, 237, 0.7)' }}>{t('examples.mode')}</span>
        <Avatar
          sx={{ marginRight: '0px', marginLeft: '10px', width: 45, height: 45, display: 'inline-block', verticalAlign: 'middle' }}
          variant="rounded"
          alt="first"
          src="/static/images/vs.png"
        />{' '}
        {t('subsVsViewers.title')}
      </div>
      <List className={classes.rootList}>
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="maps" src="/static/images/maps.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary="Maps" secondary={t('examples.mapsDesc')} />
        </ListItem>
      </List>
      <div style={{ width: '90%', minHeight: 1, backgroundColor: '#8C7CF0', marginBottom: 0 }} />
      <List className={classes.rootList}>
        <ListItem alignItems="flex-start" style={{ padding: '15px 0px' }}>
          <ListItemAvatar className={classes.avatar}>
            <Avatar alt="idea" src="/static/images/idea.png" />
          </ListItemAvatar>
          <ListItemText className={classes.text} primary={t('examples.creativeTitle')} secondary={t('examples.creativeDesc')} />
        </ListItem>
      </List>
    </Dialog>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      padding: '35px 15px',
      textAlign: 'center',
      minWidth: 320,
      alignItems: 'center',
      '@media (min-width: 601px)': {
        minWidth: 670,
        padding: '35px 25px'
      }
    }
  },
  rootList: {
    width: '90%',
    '@media (max-width: 600px)': {
      paddingRight: '0px !important',
      paddingLeft: '0px !important'
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#44D600',
    '@media (min-width: 601px)': {
      fontSize: 30,
      marginBottom: 10
    }
  },
  avatar: {
    '& .MuiAvatar-root': {
      width: 50,
      height: 50,
      '@media (min-width: 601px)': {
        width: 60,
        height: 60
      }
    }
  },
  text: {
    marginLeft: '20px !important',
    '& .MuiTypography-body1': {
      fontSize: 20
    }
  }
}))

export default ModalExamples
