import { useState, useContext } from 'react'
import axios from 'axios'
import { Dialog, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import MainProvider from 'src/contexts/MainContext'
import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone'
import { sendEvent } from 'src/services/_utils'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

const ModalContact = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ctx = useContext(MainProvider.context)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [ok, setOk] = useState(false)

  async function onSend() {
    setSending(true)
    sendEvent('contact', `${email !== '' ? email : 'noemail'} || ${message}`)
    try {
      const response = await axios.post('https://blobdance.com/api/contact', {
        email,
        message,
        ts: Date.now()
      })
      setSending(false)
      setOk(true)
      setMessage('')
      setEmail('')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dialog onClose={() => ctx.setModalContact(false)} open={ctx.modalContact} className={classes.root}>
      <div className={classes.title}>
        {t('contact.title')} <ThumbUpAltTwoToneIcon className={classes.thumb} />
      </div>
      <div className={classes.subtitle}>{t('contact.subtitle')}</div>
      <TextField
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        className={classes.email}
        label={t('contact.email')}
        variant="outlined"
      />
      <TextField
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        className={classes.message}
        label={t('contact.message')}
        multiline
        rows={5}
      />
      <div style={{ position: 'relative' }}>
        <LoadingButton
          loading={sending}
          disabled={message === ''}
          onClick={onSend}
          variant="outlined"
          size="medium"
          color="warning"
          startIcon={<SendIcon />}
        >
          {t('contact.send')}
        </LoadingButton>
        {ok && <CheckRoundedIcon color="success" fontSize="large" className={classes.ok} />}
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      padding: '35px 15px',
      textAlign: 'center',
      minWidth: 320,
      alignItems: 'center',
      '@media (min-width: 601px)': {
        minWidth: 610,
        padding: '35px 25px'
      }
    }
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#FFA319',
    '@media (min-width: 601px)': {
      fontSize: 28,
      marginBottom: 25
    }
  },
  thumb: {
    verticalAlign: 'sub'
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    marginBottom: 25,
    color: '#e3e3e3',
    '@media (min-width: 601px)': {
      fontSize: 20,
      marginBottom: 40
    }
  },
  email: {
    width: 270,
    marginBottom: '20px !important',
    '@media (min-width: 601px)': {
      width: 300,
      marginBottom: '30px !important'
    }
  },
  message: {
    width: 270,
    marginBottom: '30px !important',
    '@media (min-width: 601px)': {
      width: 450
    }
  },
  ok: {
    position: 'absolute',
    right: -50
  }
}))

export default ModalContact
