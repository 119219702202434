import App from './App'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import 'nprogress/nprogress.css'
import SidebarProvider from './contexts/SidebarContext'
import MainProvider from './contexts/MainContext'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import english from './translations/english.json'
import spanish from './translations/spanish.json'
import './animations.css'
import './global.css'

const localLanguage = localStorage.getItem('i18nextLng')

i18next.use(LanguageDetector).init({
  // debug: true,
  interpolation: { escapeValue: false },
  lng: localLanguage ?? 'en',
  load: 'languageOnly',
  supportedLngs: ['en', 'es'],
  resources: {
    en: {
      translation: english
    },
    es: {
      translation: spanish
    }
  }
})

ReactDOM.render(
  <HelmetProvider>
    <I18nextProvider i18n={i18next}>
      <SidebarProvider>
        <MainProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MainProvider>
      </SidebarProvider>
    </I18nextProvider>
  </HelmetProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
