import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  return (
    <div className={classes.root}>
      <Button variant="outlined" size="large" color="info" className={classes.button} onClick={() => i18n.changeLanguage('en-US')}>
        ENGLISH
      </Button>
      <img alt="logo" src="/static/images/blobDance3x.gif" className={classes.blobdance} />
      <Button variant="outlined" size="large" color="info" className={classes.button} onClick={() => i18n.changeLanguage('es-ES')}>
        SPANISH
      </Button>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(135deg, #1e1863 0%, #000000 100%)',
    zIndex: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  blobdance: {
    width: 60,
    height: 60,
    margin: '70px 0px !important',
    '@media (min-width: 601px)': {
      margin: '0px 70px !important'
    }
  },
  button: {
    fontSize: '26px !important'
  }
}))

export default LanguageSelector
